import { ReportDefinition } from "bps-powerbi-helper";

/**
 * This interface represents the absolute minimum we need to save  a report.
 */
interface SaveReportDefinition
  extends Omit<ReportDefinition, "rows" | "columns"> {}

export interface ReportRowVisibilityDto {
  name: string;
  role: string;
}

export interface ReportMetadataInterface {
  App?: string;
  DataSource?: string;
  BpTable?: string;
  RowVisibilityTypes?: ReportRowVisibilityDto[];
}
export interface ReportDto {
  id: string;
  name: string;
  type: ReportType;
  metadata?: ReportMetadataInterface;
}

export enum SaveType {
  saveNew = 1,
  update = 2,
  rename = 3,
  none = 4
}

export interface PresetReportDefinition extends SaveReportDefinition {
  rowVisibility: string;
  baseReportName: string;
  type: ReportType;
}

export interface ReportDefinitionDto {
  id: string;
  name: string;
  baseReportName: string;
  type: ReportType;
  definition: any;
  userId?: string;
  rowVisibility: string;
  eTag: string;
}

export interface AddReportDefinitionDto
  extends Omit<ReportDefinitionDto, "eTag" | "id"> {}

export enum ReportType {
  Base = "Base",
  UserDefined = "Usr",
  Preset = "Pre",
  None = "None",
  Published = "Pub"
}

export interface ReportSlicerValues {
  reportName: string;
  rowVisibility: string;
  isPublic: boolean;
}
export interface ReportPrintDocument {
  reportName: string;
  data: string;
}

export interface PdfRequest {
  pdfId: string;
  timeout: NodeJS.Timeout;
}
