import { ChangeLogDto, PagingOptions } from "@libs/api/dtos/index.ts";
import { RefDataDto } from "@libs/api/ref-data/dto.ts";
import { Country } from "@libs/enums/country.enum.ts";
import { PatientSummary } from "@libs/gateways/clinical/ClinicalGateway.dtos.ts";

import {
  AddressDto,
  CommunicationDto,
  CommunicationType
} from "../practice/PracticeGateway.dtos.ts";
import { PatientSummaryPageDto } from "../user-experience/UserExperienceGateway.dtos.ts";

export enum CommunicationKind {
  Phone = "PHONE",
  Email = "EMAIL",
  Open = "OPEN"
}

export interface OrgUnitDto {
  id: string;
  eTag: string;
  hierarchyType: OrgUnitHierarchyType;
  isInactive?: boolean;
  level?: number;
  name: string;
  parentOrgUnitId?: string;
  changeLog: ChangeLogDto;
  contact?: CommunicationDto[];
  addresses?: AddressDto[];
  tagLine?: string;
  timeZone?: string;
}

export interface TenantDto {
  id: string;
  name: string;
  country: Country;
}

export interface AccountDetailsDto extends UserDto {
  orgUnits: string[];
}
export interface UserDto {
  id: string;
  addresses?: AddressDto[];
  username: string;
  firstName: string;
  preferredName?: string;
  lastName: string;
  status?: UserStatus;
  title?: string;
  eTag: string;
  communications?: CommunicationDto[];
  securityRoles?: string[];
  businessRoles?: string[];
  businessRoleClasses?: string[];
  changeLog?: ChangeLogDto;
  middleName?: string;
  privateSecGroupId?: string;
  pronounSubjective?: string;
  pronounObjective?: string;
  pronounPossessive?: string;
  gender?: string;
  orgUnits?: string[];
}

export enum UserStatus {
  Pending = "PENDINGSIGNUP",
  Active = "ACTIVE",
  Inactive = "INACTIVE"
}

export interface SecGroupAccessRequestDto {
  id: string;
  userId: string;
  secGroupId: string;
  status?: SecGroupAccessRequestStatus;
  eTag: string;
  changeLog?: ChangeLogDto;
}

export interface AddSecGroupAccessRequestDto
  extends Omit<
    SecGroupAccessRequestDto,
    "id" | "eTag" | "changeLog" | "status"
  > {}

export interface UpdateSecGroupAccessRequestStatusDto {
  id: string;
  status: SecGroupAccessRequestStatus;
}

export interface LicenceDto {
  id: string;
  productId?: string;
  userId?: string;
  username?: string;
  expiryDate: string;
  doNotRenew: boolean;
  isInactive: boolean;
  isExpired: boolean;
  isValid: boolean;
  licenceTypeCode: string;
  name: string;
  displayName: string;
  description: string;
  gracePeriod: number;
  changeLog?: ChangeLogDto;
  eTag: string;
}

export interface GetLicencesDto {
  userIds?: string[];
  isInactive?: boolean;
  isExpired?: boolean;
  isValid?: boolean;
}

export interface AssignLicenceDto {
  userId: string;
  licenceType: string;
}

export interface RefCommunicationDto extends RefDataDto<CommunicationType> {
  type: CommunicationKind;
  preferredCommsOrder?: number;
  isRingable: boolean;
}

export interface RefCountryDto extends RefDataDto<Country> {
  isStateRequired: boolean;
}

export interface RefTitlesDto extends RefDataDto<string> {
  userCommon: boolean;
  contactCommon: boolean;
}

export interface RoleDto extends RefDataDto {
  permissions: string[];
}

export interface PermissionDto extends RefDataDto<Permission> {
  permissionObject: string;
}

export interface AddUserDto
  extends Omit<
    UserDto,
    "id" | "eTag" | "changeLog" | "securityRoles" | "businessRoleClasses"
  > {}

export interface PatchUserDto
  extends Patch<Omit<UserDto, "changeLog" | "eTag">> {
  id: string;
  eTag: string;
}

export interface PatchOrgUnitDto
  extends Patch<Omit<OrgUnitDto, "changeLog" | "eTag">> {
  id: string;
  eTag: string;
}

export interface AddOrgUnitDto
  extends Omit<OrgUnitDto, "changeLog" | "eTag" | "id" | "level"> {}

export interface CatalogBusinessRoleDto {
  code: string;
  text: string;
  classCode: string;
  profiles: CatalogBusinessRoleProfileDto[];
}

export interface CatalogBusinessRoleProfileDto {
  businessRoleCode: string;
  countryCode: string;
  requiredComponentCodes: string[];
  requiredLicenceTypeCodes: string[];
  defaultSecurityRoleCodes: SecurityRoleCode[];
}

export interface CatalogSecurityRoleDto {
  code: string;
  text: string;
  description: string;
}

export interface SecGroupMemberDto {
  id: string;
  tenantId: string;
  secGroupId: string;
  entityId: string;
  entityType: string;
}

export type GetSecGroupMembersDto = {
  secGroupId?: string[];
  tenantId?: string;
  entityId?: string;
  entityType?: string;
};

export enum EntityType {
  User = "USER"
}

export enum OrgUnitHierarchyType {
  Group = "G",
  Practice = "P",
  Location = "L",
  Department = "D"
}

export type GetOrgUnitsDto = {
  hierarchyType: OrgUnitHierarchyType;
  includeChildren?: boolean;
};

export interface GetUsersDto extends PagingOptions {
  search?: string;
  roles?: string[];
  statusCodes?: string[];
}
export interface ResendArgsDto {
  userId: string;
}

export enum Permission {
  PracDocWriterAllowed = "TI.PRACTICE-DOC-WRITER.ALLOWED",
  DocTemplateClinicalRead = "TI.DOCTEMPLATE-CLINICAL.READ",
  SecurityRead = "TI.SECURITY.READ",
  SecurityWrite = "TI.SECURITY.WRITE",
  UserSettingRead = "TI.USERSETTING.READ",
  UserSettingWrite = "TI.USERSETTING.WRITE",
  UserRead = "TI.USER.READ",
  UserWrite = "TI.USER.WRITE",
  PatientRead = "TI.PATIENT.READ",
  PatientWrite = "TI.PATIENT.WRITE",
  ClaimRead = "TI.CLAIM.READ",
  ClaimWrite = "TI.CLAIM.WRITE",
  ClaimDelete = "TI.CLAIM.DELETE",
  ClinicalRead = "TI.PATCLIN.READ",
  ClinicalWrite = "TI.PATCLIN.WRITE",
  CommonIntakeDetailAllowed = "TI.COMMONINTAKEDETAIL.ALLOWED",
  ContactRead = "TI.CONTACT.READ",
  ContactWrite = "TI.CONTACT.WRITE",
  CalendarEventRead = "TI.CALENDAREVENT.READ",
  CalendarEventWrite = "TI.CALENDAREVENT.WRITE",
  EncounterRead = "TI.ENC.READ",
  EncounterWrite = "TI.ENC.WRITE",
  EncounterDelete = "TI.ENC.DELETE",
  AppointmentTypeRead = "TI.APPTTYPE.READ",
  AppointmentTypeWrite = "TI.APPTTYPE.WRITE",
  ServiceRead = "TI.SERVICE.READ",
  ServiceWrite = "TI.SERVICE.WRITE",
  InvoiceView = "TI.INV.VIEW",
  InvoiceCreate = "TI.INV.CREATE",
  InvoiceCancel = "TI.INV.CANCEL",
  InvoiceAdjust = "TI.INV.ADJUST",
  PaymentCreate = "TI.PAY.CREATE",
  PaymentCancel = "TI.PAY.CANCEL",
  PaymentAdjust = "TI.PAY.ADJUST",
  CreditCreate = "TI.CREDITS.CREATE",
  CreditCancel = "TI.CREDITS.CANCEL",
  CreditAdjust = "TI.CREDITS.ADJUST",
  AccountHistoryAllowed = "TI.ACCOUNTHISTORY.ALLOWED",
  ProviderRead = "TI.PROVIDER.READ",
  ProviderWrite = "TI.PROVIDER.WRITE",
  BookingScheduleRead = "TI.BOOKINGSCHEDULE.READ",
  BookingScheduleWrite = "TI.BOOKINGSCHEDULE.WRITE",
  OpeningHoursRead = "TI.OPENINGHOURS.READ",
  OpeningHoursWrite = "TI.OPENINGHOURS.WRITE",
  OrgUnitSettingRead = "TI.ORGUNITSETTING.READ",
  OrgUnitSettingWrite = "TI.ORGUNITSETTING.WRITE",
  OrgUnitRead = "TI.ORGUNIT.READ",
  OrgUnitWrite = "TI.ORGUNIT.WRITE",
  SnippetWrite = "TI.SNIPPET.WRITE",
  SnippetDelete = "TI.SNIPPET.DELETE",
  ScheduleRead = "TI.SCHEDULE.READ",
  ScheduleWrite = "TI.SCHEDULE.WRITE",
  PreRelease = "TI.PREREL.ALLOWED",
  SendApptRemindersAllowed = "TI.SENDAPPTREMINDERS.ALLOWED",
  SendRemindersWrite = "TI.SENDAPPOINTMENTREMINDERS.WRITE",
  CommsCreditsRead = "TI.COMMS-CREDITS.READ",
  ReportBaseRead = "TI.REPORT-BASE.READ",
  ReportPresetRead = "TI.REPORT-PRESET.READ",
  ReportPublishedRead = "TI.REPORT-PUBLISHED.READ",
  ReportPublishedWrite = "TI.REPORT-PUBLISHED.WRITE",
  ReportExecute = "TI.REPORT.EXECUTE",
  DocWorkflowRead = "TI.DOCWORKFLOW.READ",
  DocWorkflowDelete = "TI.DOCWORKFLOW.DELETE",
  InboxRead = "TI.INBOX.READ",
  FollowUpTasks = "TI.FOLLOWUPTASK-MANAGE.ALLOWED",
  RxRead = "TI.RX.READ",
  RxRecordAllowed = "TI.RX-RECORD.ALLOWED",
  RxPrescribeAllowed = "TI.RX-PRESCRIBE.ALLOWED",
  ClinTaskRead = "TI.CLINTASK.READ",
  ClinTaskWrite = "TI.CLINTASK.WRITE",
  ClinTaskDelete = "TI.CLINTASK.DELETE",
  ClinTaskUnlock = "TI.CLINTASK.UNLOCK",
  ClinActivityAllowed = "TI.CLINICAL-ACTIVITIES.ALLOWED",
  CreateClinActivityAllowed = "TI.CREATE-CLINICAL-ACTIVITIES.ALLOWED",
  ClinActivityRead = "TI.CLINACTIVITY.READ",
  ClinActivityUnlock = "TI.CLINACTIVITY.UNLOCK",
  ClinActivityWrite = "TI.CLINACTIVITY.WRITE",
  ClinActivityDelete = "TI.CLINACTIVITY.DELETE",
  SOTAPInitialEncounter = "TI.SOTAP.INITIALENCOUNTER",
  SOTAPFollowOn = "TI.SOTAP.FOLLOWONENCOUNTER",
  TreeviewClaimsAllowed = "TI.TREEVIEW.CLAIMS",
  SmsUnderDevelopment = "TI.APPTREMIND.UNDERDEVELOPMENT",
  AppointmentReminderScheduleRead = "TI.APPTREMSCHEDULE.READ",
  AppointmentReminderScheduleWrite = "TI.APPTREMSCHEDULE.WRITE",
  CommunicationTemplateRead = "TI.COMMTEMPLATE.READ",
  CommunicationTemplateWrite = "TI.COMMTEMPLATE.WRITE",
  PreReleaseAccContractServices = "TI.ACCCONTRACTSERVICES.PREREL",
  ClaimAdjustmentDiagnose = "TI.CLAIMADJUSTMENT.DIAGNOSE",
  PhysioDischarge = "TI.PHYSIO.DISCHARGE",
  TenantSettingWrite = "TI.TENANTSETTING.WRITE",
  SyncFusionEditor = "TI.SYNCFUSION.EDITOR",
  SendForms = "TI.SENDFORMS.ALLOWED",
  PreReleaseAccInvoices = "TI.ACCINVOICESTATUSES.PREREL",
  ClaimReviewReducedMinimumVisits = "TI.CLAIMS-REVIEW-MIN.VISITS",
  FinancialSettingsRead = "TI.FINANCIALSETTINGS.READ",
  FinancialSettingsWrite = "TI.FINANCIALSETTINGS.WRITE",
  NAWrite = "TI.NA.WRITE",
  ApptConfirmationsAllowed = "TI.APPTCONF.ALLOWED",
  InactiveLockScreenAllowed = "TI.INACTIVE-LOCK-SCREEN.ALLOWED",
  ClinSettingAllowed = "TI.CLINSETTING.ALLOWED",
  StatementRead = "TI.STATEMENT.READ",
  StatementWrite = "TI.STATEMENT.WRITE",
  ReduceMfaTimerAllowed = "TI.REDUCE-MFA-TIMER.ALLOWED",
  TemplateManageAllowed = "TI.MANAGETEMPLATE.ALLOWED",
  GroupAppointmentsAllowed = "TI.GROUPAPPOINTMENTS.ALLOWED",
  IncomingReportsAllowed = "TI.INCOMING-REPORTS.ALLOWED",
  UploadDocsAllowed = "TI.UPLOAD-DOCS.ALLOWED",
  BhbWrite = "BH.BOOKING.WRITE",
  BhbRead = "BH.BOOKING.READ",
  BhbConfigAllowed = "TI.BHB-CONFIG.ALLOWED",
  MultiProviderClaimsAllowed = "TI.MULTI-PROVIDER-CLAIMS.ALLOWED",
  DocumentTemplateRead = "TI.DOCTEMPLATE.READ",
  DocumentTemplateWrite = "TI.DOCTEMPLATE.WRITE",
  DocumentTemplateDelete = "TI.DOCTEMPLATE.DELETE",
  WriteOffAllowed = "TI.WRITEOFFS.ALLOWED",
  CreditAllowed = "TI.CREDITS.ALLOWED",
  WriteOffCreate = "TI.WRITEOFFS.CREATE",
  WriteOffCancel = "TI.WRITEOFFS.CANCEL",
  RefundAllowed = "TI.REFUNDS.ALLOWED",
  RefundCreate = "TI.REFUNDS.CREATE",
  ConfidentialDataAllowed = "TI.CONFIDENTIAL-DATA.ALLOWED",
  AccPaymentProcessingPrerel = "TI.ACCPAYMENTPROCESSING.PREREL",
  ManageIntegrationsAllowed = "TI.MANAGE-INTEGRATIONS.ALLOWED",
  ExpandedHandInjuryAreaAllowed = "TI.EXTENDED-WRIST-ROM.ALLOWED",
  SaveDraftAllowed = "TI.DRAFTINVOICES.ALLOWED",
  InactivityTimeoutSettingsAllowed = "TI.INACTIVITY-TIMEOUT-SETTINGS.ALLOWED",
  FormDesignRead = "TI.FORMDESIGN.READ",
  FormDesignWrite = "TI.FORMDESIGN.WRITE",
  ClinicalFormsAllowed = "TI.CLINICALFORMS.ALLOWED",
  PreReleaseMultiLocationAllowed = "TI.MULTILOCATION.ALLOWED",
  ClinicalDataSync = "TI.CLINICAL-DATA-SYNC.ALLOWED",
  MultiEncounters = "TI.MULTIPLE-ENCOUNTERS.ALLOWED",
  PatientMergeAllowed = "TI.PATIENTMERGE.ALLOWED",
  PatientMergeJobRead = "TI.PATIENTMERGEJOB.READ",
  PatientMergeJobWrite = "TI.PATIENTMERGEJOB.WRITE",
  PreReleaseBookingTenantSettingsAllowed = "TI.BOOKINGTENANTSETTINGS.ALLOWED",
  LicencingAllowed = "TI.LICENCING.ALLOWED",
  CentralNervousSystemAllowed = "TI.CENTRAL-NERVOUS-SYSTEM.ALLOWED",
  EPDSAllowed = "TI.EPDS-CLINICAL-TOOL.ALLOWED",
  DASS21Alowed = "TI.DASS21.ALLOWED",
  ClaimLodgementAllowed = "TI.CLAIM-LODGEMENT.ALLOWED",
  BillingAllowed = "TI.BILLING.ALLOWED",
  PatientMatchAllowed = "TI.PATIENTMATCH.ALLOWED",
  TemplateManagementAllowed = "TI.TEMPLATEMANAGEMENT.ALLOWED",
  TemplateManagementRead = "TI.TEMPLATEMANAGEMENT.READ",
  TemplateManagementWrite = "TI.TEMPLATEMANAGEMENT.WRITE",
  CreateConsultAllowed = "TI.CREATE-CONSULT.ALLOWED",
  EoCWrite = "TI.EOC.WRITE",
  EoCRead = "TI.EOC.READ",
  CreateClinicalTasksAllowed = "TI.CREATE-CLINICAL-TASKS.ALLOWED",
  CreateClinicalActivitiesAllowed = "TI.CREATE-CLINICAL-ACTIVITIES.ALLOWED",
  QuickAccessAllowed = "TI.QUICKACCESS.ALLOWED",
  UnlinkConditionAllowed = "TI.UNLINK-CONDITION.ALLOWED",
  GpExamCompleteAllowed = "TI.GPEXAMCOMPLETE.ALLOWED",
  EncounterTimerAllowed = "TI.ENCOUNTERTIMER.ALLOWED",
  OnHoldClaimAllowed = "TI.ONHOLD-CLAIMS.ALLOWED",
  AutofillAllowed = "TI.AUTOFILLS.ALLOWED",
  ApptTypeBaseIntervalAllowed = "TI.APPTTYPE-BASE-INTERVAL.ALLOWED",
  AccUpdateNonReferredClaimNumberAllowed = "TI.ACC-UPDATE-NON-REFERRED-CLAIM-NUMBER.ALLOWED",
  ConditionsAllowed = "TI.CONDITIONS.ALLOWED",
  MedicationsAndSupplementsAllowed = "TI.MEDANDSUPHISTORY.ALLOWED",
  StatementRunAllowed = "TI.STATEMENT-RUN.ALLOWED",
  NewObservationEnitity = "TI.NEWCLINOBSENTITY.ALLOWED",
  ClinicalReminderPreferenceAllowed = "TI.CLINICALREMINDERSPREFERENCE.ALLOWED",
  ExportPatientRecordAllowed = "TI.ExportSinglePatient.PREREL",
  PatientTreatmentPlanAllowed = "TI.PATIENTTREATMENTPLAN.ALLOWED",
  UIRedesignAllowed = "TI.UIREDESIGN.PREREL",
  MedicalHistoryDiagnosisSyncAllowed = "TI.MEDICALHISTORYDIAGNOSISSYNC.ALLOWED",
  ConditionDischargeOnBehalfAllowed = "TI.CONDITION-DISCHARGE-ON-BEHALF.ALLOWED",
  ClaimListDischargeAllowed = "TI.CLAIMLIST-DISCHARGE.ALLOWED",
  ClinicalAdminPrerel = "TI.CLINICAL-ADMIN.PREREL",
  ActivityDescriptionWrite = "TI.ACTIVITY-DESCRIPTION.WRITE",
  ReverseDischargeWorkflowReworkAllowed = "TI.REVDISCHARGE-REWORK.ALLOWED",
  ACCClaimSyncMedicalHistoryAllowed = "TI.ACCMEDICALHISOTRYSYNC.ALLOWED",
  RecentPatientsAdminAllowed = "TI.RECENT-PATIENTS-ADMIN.ALLOWED",
  ClinicalReminderFeatureToggle = "TI.CLINREMINDER.PREREL",
  ClinicalReminderWrite = "TI.CLINREMINDER.WRITE",
  ClinicalReminderRead = "TI.CLINREMINDER.READ",
  ClinicalReminderDelete = "TI.CLINREMINDER.DELETE",
  ClinicalReminderSend = "TI.CLINREMINDER.SEND",
  ClinicalReminderCreate = "TI.CLINREMINDER.CREATE",
  ClinicalReminderAllowed = "TI.CLINREMINDER.ALLOWED"
}

export interface PatientSummaryItem extends PatientSummaryPageDto {
  iconName: string;
  permission?: string;
}

type PatientSummaryIcon = {
  key: string;
  iconName: string;
};

export const patientSummaryIcons: PatientSummaryIcon[] = [
  { key: PatientSummary.FamilySocial, iconName: "Family" },
  {
    key: PatientSummary.ClinicalTasks,
    iconName: "TaskSolid"
  },
  {
    key: PatientSummary.ClinicalNotifications,
    iconName: "TaskSolid"
  },
  { key: PatientSummary.CommentsFlags, iconName: "Megaphone" },
  { key: PatientSummary.MedicalHistory, iconName: "BpMedHx" },
  { key: PatientSummary.Medication, iconName: "Pill" },
  {
    key: PatientSummary.RecentInvestigationsDocument,
    iconName: "SearchAndApps"
  },
  { key: PatientSummary.RecentObservations, iconName: "HistoricalWeather" },
  { key: PatientSummary.NotesFromPreviousEncounter, iconName: "EditNote" },
  { key: PatientSummary.ReasonForVisit, iconName: "SchoolDataSyncLogo" },
  { key: PatientSummary.ClinicalTools, iconName: "Hospital" }
];

export enum SecurityRoleCode {
  UserManagementAdmin = "TI.USER-ADMIN",
  PracInfoAdmin = "TI.PRAC-ADMIN",
  PracInfoContributor = "TI.PRAC-CONTRIB",
  PracWorkflowsAdmin = "TI.WORK-ADMIN",
  PracWorkflowsContributor = "TI.WORK-CONTRIB",
  AppointmentsAdmin = "TI.BOOK-ADMIN",
  AppointmentsContributor = "TI.BOOK-CONTRIB",
  AppointmentsRead = "TI.BOOK-READ",
  AccountsAdmin = "TI.BILL-ADMIN",
  AccountsContributor = "TI.BILL-CONTRIB",
  AccountsRead = "TI.BILL-READ",
  ClaimingAdmin = "TI.CLAIM-ADMIN",
  ClaimingContributor = "TI.CLAIM-CONTRIB",
  ClaimingRead = "TI.CLAIM-READ",
  ClinicalContributor = "TI.CLIN-CONTRIB",
  ClinicalAdmin = "TI.CLIN-ADMIN",
  ClinicalRead = "TI.CLIN-READ",
  BhbContributor = "TI.BHB-CONTRIB",
  ReportAdmin = "TI.REPORT-ADMIN",
  ReportRead = "TI.REPORT-READ",
  LicensedWfContributor = "TI.LIC-CONTRIB"
}

export enum SecurityInitialsKeyword {
  Titanium = "TI"
}

export enum SecurityAreaKeyword {
  UserManagement = "USER",
  PracInfo = "PRAC",
  PracWork = "WORK",
  Appointments = "BOOK",
  Accounts = "BILL",
  Claiming = "CLAIM",
  Clinical = "CLIN",
  Bhb = "BHB",
  Report = "REPORT",
  Licencing = "LIC"
}

export enum SecurityTypeKeyword {
  Administrator = "ADMIN",
  Contributor = "CONTRIB",
  Read = "READ",
  None = "NONE",
  Reset = "Reset-to-default"
}

export type SecurityPermission = {
  area: SecurityAreaKeyword;
  areaLabel: string;
};

export enum BusinessRoleClasses {
  Provider = "PROVIDER",
  Owner = "OWNER",
  PracticeManager = "PRACTICEMANAGER"
}

export enum LicenceTypeCodes {
  AlliedNZ = "TI.ALLIED-NZ"
}

export enum SecGroupAccessRequestStatus {
  Approved = "APPROVED",
  Denied = "DENIED"
}

export interface UserAuthorisationDto {
  permissions: string[];
  tenant: TenantDto;
}

export enum BusinessRole {
  Physiotherapist = "TI.PHYSIO",
  OccupationalTherapist = "TI.OCCTH",
  HandTherapist = "TI.HANDTH",
  Chiropractor = "TI.CHIRO",
  Osteopath = "TI.OSTEO",
  SpeechTherapist = "TI.SPPTH",
  Podiatrist = "PODIA",
  Acupuncturist = "TI.ACUPUN"
}
